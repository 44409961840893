<template>
  <div id="sticPay">
    <div class="content_box">
      <div class="inner_max">
        <div class="form_deposit">
          <h4>SticPay</h4>
          <div class="content">
            <div class="info_box">
              <img src="@/assets/images/channel/sticPay.png" alt />
              <p>{{ $t('deposit.method.desc', { method: 'SticPay' }) }}</p>
              <ul>
                <li>{{ $t('deposit.method.inst1', { number: '1' }) }}</li>
                <li>{{ $t('deposit.sticPay.inst2') }}</li>
                <li>{{ $t('deposit.sticPay.inst3') }}</li>
              </ul>
            </div>
            <div class="form_main">
              <p class="title">{{ $t('deposit.method.form.header', { method: 'SticPay' }) }}</p>
              <el-form label-position="top" :model="sticPayForm" ref="sticPayForm" :rules="sticPayRules" status-icon>
                <div class="form_box">
                  <ul class="clearfix">
                    <li class="fl">
                      <AccountNumber
                        :supportedCurrenciesList="supportedCurrencies"
                        @setCurrency="setCurrency"
                        @setAccountNumber="setAccountNumber"
                      ></AccountNumber>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.amt')" prop="amount">
                        <numeric-input
                          v-model="sticPayForm.amount"
                          :currency="accountCurrency"
                          :precision="2"
                        ></numeric-input>
                      </el-form-item>
                    </li>
                  </ul>
                  <ul class="clearfix">
                    <li class="fl">
                      <el-form-item :label="$t('withdraw.sticpay.field.email')" prop="email">
                        <el-input v-model="sticPayForm.email" data-testid="email"></el-input>
                      </el-form-item>
                    </li>
                    <li class="fr">
                      <el-form-item :label="$t('common.field.imptNotes')">
                        <el-input v-model="sticPayForm.notes" data-testid="applicationNotes"></el-input>
                      </el-form-item>
                    </li>
                  </ul>
                </div>
                <el-button type="primary" :loading="loading" @click="submitForm()" data-testid="submit">
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
        <form id="sticPayForm" action="https://pay.sticpay.com/1.1/pay" method="post" style="display: none">
          <input name="callback_url" :value="sticpayApiForm.callback_url" />
          <input name="failure_url" :value="sticpayApiForm.failure_url" />
          <input name="interface_version" :value="sticpayApiForm.interface_version" />
          <input name="merchant_email" :value="sticpayApiForm.merchant_email" />
          <input name="order_amount" :value="sticpayApiForm.order_amount" />
          <input name="order_currency" :value="sticpayApiForm.order_currency" />
          <input name="order_no" :value="sticpayApiForm.order_no" />
          <input name="order_time" :value="sticpayApiForm.order_time" />
          <input name="referrer_url" :value="sticpayApiForm.referrer_url" />
          <input name="registered_email" :value="sticpayApiForm.registered_email" />
          <input name="sign" :value="sticpayApiForm.sign" />
          <input name="sign_type" :value="sticpayApiForm.sign_type" />
          <input name="success_url" :value="sticpayApiForm.success_url" />
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import { apiStic_payment } from '@/resource';
import mixin from '@/mixins/page/deposit';

export default {
  name: 'SticPay',
  components: { NumericInput, AccountNumber },
  mixins: [mixin],
  data() {
    const validateAmount = (rule, value, callback) => {
      if (value === '' || !Number(value)) {
        callback(new Error(this.$t('common.formValidation.amtReq')));
      } else if (parseFloat(value) < this.minLimit) {
        callback(new Error(this.$t('common.formValidation.amtLarger', { minLimit: this.minLimit })));
      } else if (parseFloat(value) > this.maxLimit) {
        callback(
          new Error(
            this.$t('common.formValidation.amtLess', { maxLimit: this.maxLimit, currency: this.accountCurrency })
          )
        );
      } else {
        callback();
      }
    };
    return {
      sticPayForm: {
        accountNumber: '',
        amount: '',
        email: '',
        notes: ''
      },
      sticPayRules: {
        accountNumber: [
          {
            required: true,
            message: this.$t('common.formValidation.accReq'),
            trigger: 'change'
          }
        ],
        amount: [
          {
            required: true,
            validator: validateAmount,
            trigger: 'blur'
          }
        ]
      },
      sticpayApiForm: {
        callback_url: null,
        failure_url: null,
        interface_version: null,
        merchant_email: null,
        order_amount: null,
        order_currency: null,
        order_no: null,
        order_time: null,
        referrer_url: null,
        registered_email: null,
        sign: null,
        sign_type: null,
        success_url: null
      },
      supportedCurrencies: ['USD', 'AUD', 'CAD', 'EUR', 'GBP', 'NZD', 'SGD', 'JPY', 'HKD']
    };
  },
  methods: {
    setAccountNumber(accountNumber) {
      this.sticPayForm.accountNumber = accountNumber;
    },
    submitForm(e) {
      this.$refs['sticPayForm'].validate(valid => {
        if (valid) {
          this.loading = true;
          this.submitDeposit()
            .then(result => {
              if (result.data.code == 0) {
                this.sticpayApiForm = result.data.data.sticParams;
                this.$nextTick(function () {
                  if (document.getElementById('sticPayForm')) {
                    document.getElementById('sticPayForm').submit();
                  } else {
                    this.loading = false;
                    this.errorMessage(this.$t('deposit.default.failed'));
                  }
                });
              } else {
                this.loading = false;
                this.errorMessage(this.$t('deposit.default.failed'));
              }
            })
            .catch(err => {
              this.loading = false;
              this.errorMessage(this.$t('deposit.default.failed'));
            });
        } else {
          return false;
        }
      });
    },
    submitDeposit() {
      return apiStic_payment(
        {
          mt4Account: this.sticPayForm.accountNumber,
          operateAmount: this.sticPayForm.amount,
          applicationNotes: this.sticPayForm.notes,
          skrillEmail: this.sticPayForm.email
        },
        this.token
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
